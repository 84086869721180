<template>
	<div class="d-flex flex-column position-relative">
		<b-input-group>
			<b-form-input
				:disabled="this.$props.isEditable() === false"
				v-model="hourValid"
				@input="showDateTimePickers ? setScrollable() : onChange($event)"
				:type="this.$props.type"
				:v-uni-id="this.$props.uiid"
				:placeholder="this.$props.placeholder"
				v-hour-minute="workType"
				@keyup.enter="() => onEnter()"
				:class="{
					'is-invalid': this.$props.isSubmitted && this.$props.error,
					'br-none': iconBg !== ''
				}"
				v-on:touchstart.prevent="() => openDateTimePickersPersistent()"
				v-on:click.prevent="() => openDateTimePickersPersistent()"
			/>
			<b-input-group-append>
				<b-input-group-text @click="openDateTimePickers()" :class="`${this.$props.isSubmitted && this.$props.error ? iconBg + ' is-invalid' : iconBg}`">
					<component :is="getLucideIcon(ICONS.CLOCK.name)" :color="iconColor" :size="iconSize" :stroke-width="iconStrokeWidth" />
				</b-input-group-text>
			</b-input-group-append>
		</b-input-group>
		<div v-if="$screen.width <= 992 && this.isEditable() && showDateTimePickers" class="modal">
			<div class="modal-content" :style="{ width: modalWidth, height: modalHeight }">
				<div v-if="$screen.width <= 992" class="d-flex flex-row flex-wrap gp-btn header-date-time-pickers-mobile">
					<div class="fs-400">{{ label }}: {{ hourValid }}</div>
				</div>
				<div class="d-flex flex-row">
					<div class="d-flex flex-column">
						<div id="scrollable-hour" ref="listScrollableHour" :style="[$screen.width <= 992 && { height: '200px' }]">
							<li v-for="hour of hours" :key="hour.value" :class="hour.isSelect && 'selected'" v-on:click="setHour(hour)">
								{{ hour.value }}
							</li>
						</div>
					</div>
					<div class="d-flex flex-column">
						<div id="scrollable-minute" ref="listScrollableMinute" :style="[$screen.width <= 992 && { height: '200px' }]">
							<li v-for="minute of minutes" :key="minute.value" :class="minute.isSelect && 'selected'" v-on:click="setMinute(minute)">
								{{ minute.value }}
							</li>
						</div>
					</div>
				</div>
				<div class="d-flex flex-row justify-content-center gp-btn">
					<b-button variant="outline-primary" class="d-flex justify-content-center col-5" @click="closeDatePickers()">
						<i class="fa fa-times"></i>
					</b-button>
					<b-button variant="outline-primary" class="d-flex justify-content-center col-5" @click="onSaveDatePickers()">
						<i class="fa fa-check"></i>
					</b-button>
				</div>
			</div>
		</div>
		<div v-else-if="$screen.width > 992 && showDateTimePickers">
			<div id="dateTimePosition" ref="dateTimePosition" :class="'date-time-pickers' + ' ' + setRotateClass">
				<div class="d-flex flex-column">
					<div class="d-flex flex-row">
						<div id="scrollable-hour" ref="listScrollableHour">
							<li v-for="hour of hours" :key="hour.value" :class="hour.isSelect && 'selected'" v-on:click="setHour(hour)">
								{{ hour.value }}
							</li>
						</div>
						<div id="scrollable-minute" ref="listScrollableMinute">
							<li v-for="minute of minutes" :key="minute.value" :class="minute.isSelect && 'selected'" v-on:click="setMinute(minute)">
								{{ minute.value }}
							</li>
						</div>
					</div>
					<div class="d-flex flex-row justify-content-center gp-btn">
						<b-button variant="outline-primary" class="d-flex justify-content-center col-5" @click="closeDatePickers()">
							<i class="fa fa-times"></i>
						</b-button>
						<b-button variant="outline-primary" class="d-flex justify-content-center col-5" @click="onSaveDatePickers()">
							<i class="fa fa-check"></i>
						</b-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import globalMixin from '@/mixins/global.mixin';
import languageMessages from '@/mixins/languageMessages';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import * as _ from 'lodash';

export default {
	name: 'DateTimePickers',
	mixins: [globalMixin, languageMessages, isSingleProjectMixin],
	props: {
		uiid: {
			type: String,
			require: false
		},
		type: { type: String, require: false },
		placeholder: { type: String, require: false },
		error: {
			type: Boolean,
			require: false
		},
		isSubmitted: {
			type: Boolean,
			require: false
		},
		getTotMinPerDay: {
			type: Function,
			require: false
		},
		isEditable: {
			type: Function,
			require: false
		},
		nbHour: {
			type: Number,
			required: false
		},
		nbMinute: {
			type: Number,
			required: false
		},
		item: {
			type: String,
			required: false
		},
		onClose: {
			type: Function,
			required: false
		},
		fieldName: {
			type: String,
			require: false
		},
		workType: {
			type: Number,
			required: true,
			default: 1 // 0: 'hourly', 1: 'daily', 2: 'weekly', 3: 'monthly', 4: 'yearly'
		},
		label: {
			type: String,
			required: false,
			default: 'Selected hour'
		},
		iconColor: {
			type: String,
			required: false,
			default: '#06263E'
		},
		iconStrokeWidth: {
			type: Number,
			required: false,
			default: 2
		},
		iconSize: {
			type: Number,
			required: false,
			default: 16
		},
		iconBg: {
			type: String,
			required: false,
			default: ''
		}
	},
	data() {
		return {
			originValue: this.$props.item,
			setRotateClass: '',
			isScrolling: false,
			isClicked: false,
			value: null,
			showDateTimePickers: false,
			dateTime: undefined,
			hours: [],
			minutes: [],
			hourValid: '00:00',
			hourDefault: '00',
			minuteDefault: '00',
			modalWidth: '240px',
			modalHeight: 'auto'
		};
	},
	watch: {
		item: {
			handler(newVal) {
				newVal = !_.isNil(newVal) ? newVal : '00:00';
				this.hourValid = newVal;
				this.hourDefault = newVal.split`:`[0];
				this.minuteDefault = newVal.split`:`[1];
			},
			immediate: true,
			deep: true
		},
		datePickerPosition(newValue) {
			this.setRotateClass = newValue;
		},
		nbHour: {
			handler(newVal) {
				this.nbHour = newVal;
				this.initialize();
				this.setScrollable();
			},
			immediate: true,
			deep: true
		}
	},
	created() {
		this.initialize();
	},
	mounted() {
		this.isCheckedDatePickers();
		this.$nextTick(() => {
			window.addEventListener('click', this.setRotate);
			window.addEventListener('wheel', this.setRotate);
			document.addEventListener('wheel', this.handleWheel);
			document.addEventListener('click', this.handleWheel);
		});
	},
	beforeDestroy() {
		window.removeEventListener('click', this.setRotate);
		window.removeEventListener('wheel', this.setRotate);
		document.removeEventListener('wheel', this.handleWheel);
		document.removeEventListener('click', this.handleWheel);
	},
	computed: {
		datePickerPosition() {
			if (this.showDateTimePickers) {
				setTimeout(() => {
					this.dateTime = this.$refs.dateTimePosition;
				}, 10);
				if (this.dateTime !== undefined) {
					let dateTimePosition = this.dateTime.getBoundingClientRect();
					const topLimit = 50;
					const bottomLimit = window.innerHeight - 50;
					if (dateTimePosition.top < topLimit) return (this.setRotateClass = 'date-time-bottom');
					else if (dateTimePosition.bottom > bottomLimit) return (this.setRotateClass = 'date-time-top');
					else return (this.setRotateClass = 'date-time-bottom');
				}
			}
		}
	},
	methods: {
		showModal() {
			this.$root.$emit('bv::show::modal', 'modal-1', '#btnShow');
		},
		hideModal() {
			this.$root.$emit('bv::hide::modal', 'modal-1', '#btnShow');
		},
		toggleModal() {
			this.$root.$emit('bv::toggle::modal', 'modal-1', '#btnToggle');
		},
		initialize() {
			this.hours = Array.from({ length: this.nbHour }, (v, i) => +i).map((x) => {
				if (x <= 9) return { value: '0' + x, isSelect: false };
				return { value: x, isSelect: false };
			});
			this.minutes = Array.from({ length: this.nbMinute }, (v, i) => +i).map((x) => {
				if (x <= 9) return { value: '0' + x, isSelect: false };
				return { value: x, isSelect: false };
			});
		},
		handleWheel() {
			this.setRotate;
		},
		onChange(event) {
			if (this.$refs.dateTimePosition === undefined) {
				let hour = event.split`:`[0];
				let minute = event.split`:`[1];
				if (+hour < +this.nbHour) {
					this.hourDefault = hour;
				}
				if (+minute < +this.nbMinute) {
					this.minuteDefault = minute;
				}
				this.hourValid = this.hourDefault + ':' + this.minuteDefault;
				this.$emit('change', { hourValid: this.hourValid, fieldName: this.$props.fieldName });
			}
		},
		setOriginValue() {
			this.originValue = this.item;
			this.hourValid = this.originValue;
		},
		setRotate() {
			if (this.showDateTimePickers) {
				setTimeout(() => {
					this.dateTime = this.$refs.dateTimePosition;
				}, 10);
				if (this.dateTime !== undefined) {
					let dateTimePosition = this.dateTime.getBoundingClientRect();
					const scrollY = window.scrollY;
					const topLimit = 50;
					const bottomLimit = window.innerHeight - 50;
					if (dateTimePosition.top < topLimit) return (this.setRotateClass = 'date-time-bottom');
					else if (dateTimePosition.bottom > bottomLimit) return (this.setRotateClass = 'date-time-top');
					else return (this.setRotateClass = 'date-time-bottom');
				}
			}
		},
		setScrollable() {
			const listElementHour = this.$refs.listScrollableHour;
			const listElementMinute = this.$refs.listScrollableMinute;
			const inputValueHour = !_.isNil(this.hourValid) ? this.hourValid.split`:`[0] : '00';
			const inputValueMinute = !_.isNil(this.hourValid) ? this.hourValid.split`:`[1] : '00';
			if (listElementHour && listElementMinute) {
				const listItemsHour = listElementHour.getElementsByTagName('li');
				const listItemsMinute = listElementMinute.getElementsByTagName('li');
				for (let i = 0; i < listItemsHour.length; i++) {
					let listItemValue = listItemsHour[i].textContent;
					if (listItemValue.includes(inputValueHour)) {
						const listItemOffsetTop = listItemsHour[i].offsetTop;
						listElementHour.scrollTo({
							top: listItemOffsetTop - 100,
							behavior: 'smooth'
						});
						this.hours = this.hours.map((x) => {
							if (x.value == inputValueHour) return { ...x, isSelect: true };
							return { ...x, isSelect: false };
						});
						break;
					}
				}

				for (let i = 0; i < listItemsMinute.length; i++) {
					let listItemValue = listItemsMinute[i].textContent;
					if (listItemValue.includes(inputValueMinute)) {
						let listItemOffsetTop = listItemsMinute[i].offsetTop;
						listElementMinute.scrollTo({
							top: listItemOffsetTop - 100,
							behavior: 'smooth'
						});
						this.minutes = this.minutes.map((x) => {
							if (x.value == inputValueMinute) return { ...x, isSelect: true };
							return { ...x, isSelect: false };
						});
						break;
					}
				}
			}
		},
		openDateTimePickers() {
			this.$props.isEditable() &&
				((this.showDateTimePickers = !this.showDateTimePickers),
				this.isCheckedDatePickers(),
				setTimeout(() => {
					this.setScrollable();
				}, 10));
		},
		openDateTimePickersPersistent() {
			this.showDateTimePickers = true;
			this.isCheckedDatePickers();
			setTimeout(() => {
				this.setScrollable();
			}, 10);
		},
		isCheckedDatePickers() {
			if (!_.isNil(this.hourValid)) {
				let _hour = this.hourValid.split`:`[0];
				let _minute = this.hourValid.split`:`[1];
				this.hours = this.hours.map((hour) => {
					if (hour.value == _hour) {
						this.hoursDefault = _hour;
						return { ...hour, isSelect: true };
					}
					return { ...hour, isSelect: false };
				});
				this.minutes = this.minutes.map((minute) => {
					if (+minute.value == _minute) {
						this.minuteDefault = _minute;
						return { ...minute, isSelect: true };
					}
					return { ...minute, isSelect: false };
				});
			}
		},
		closeDatePickers() {
			this.openDateTimePickers();
			this.setOriginValue();
		},
		onSaveDatePickers() {
			if (this.hoursDefault !== undefined && this.minuteDefault !== undefined && this.hourValid !== undefined) {
				this.$emit('change', { hourValid: this.hourValid, fieldName: this.$props.fieldName });
				return this.closeDatePickers();
			}
			return this.closeDatePickers();
		},
		onEnter() {
			this.$emit('change', { hourValid: this.hourValid, fieldName: this.$props.fieldName });
			this.closeDatePickers();
		},
		setHour(item) {
			this.setRotate();
			return (this.hours = this.hours.map((hour) => {
				if (hour.value === item.value) {
					this.hoursDefault = item.value;
					this.hourValid = this.hoursDefault + ':' + this.minuteDefault;
					return { ...hour, isSelect: true };
				}
				return { ...hour, isSelect: false };
			}));
		},
		setMinute(item) {
			this.setRotate();
			return (this.minutes = this.minutes.map((minute) => {
				if (minute.value === item.value) {
					this.minuteDefault = item.value;
					this.hourValid = this.hoursDefault + ':' + this.minuteDefault;
					return { ...minute, isSelect: true };
				}
				return { ...minute, isSelect: false };
			}));
		}
	}
};
</script>

<style scoped>
#scrollable-hour {
	width: 100px;
	height: 20vh;
	overflow-y: scroll;
	margin-bottom: 10px;
	border-right: 1px solid #cad0d7;
	cursor: pointer;
}
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}
#scrollable-minute {
	width: 100px;
	height: 20vh;
	overflow-y: scroll;
	margin-bottom: 10px;
	cursor: pointer;
}
.header-date-time-pickers-mobile {
	width: 200px;
}
.gp-btn {
	gap: 10px;
	padding: 5px 5px;
	background-color: #f8f9fb;
}
ul {
	display: flex;
	align-items: center;
	justify-content: center;
	list-style: none;
	flex-wrap: nowrap;
	padding: 0;
	margin: 0;
}

li {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 30px;
	text-align: center;
}
.selected {
	background-color: #b8e4e3;
}

.date-time-pickers {
	position: absolute;
	top: 100%;
	background-color: #ffff;
	z-index: 999;
}
.date-time-bottom {
	top: 100%;
}
.date-time-top {
	position: absolute;
	background-color: #ffff;
	z-index: 999;
	transform: translate(0%, -114%);
}

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal-content {
	background-color: white;
	padding: 20px;
	border-radius: 5px;
}
</style>
